@import "../marketplace/index.module.scss";

.marketplace__rhinox__container {
  @extend .marketplace__container;

  padding: 40px 0 60px 0;
}

.marketplace__rhinox__heading {
  padding-bottom: 30px;
}

.marketplace__rhinox__subtitle {
  @extend .marketplace__subtitle;

  font-size: 24px;
}

.marketplace__rhinox__nftcard {
  display: flex;
  justify-content: center;
  max-width: 700px;
}
