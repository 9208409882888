@import "../../assets/scss/abstracts/variables";

.modalContent {
  color: $dark2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  button {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 4px;

  input[type="checkbox"] {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    appearance: none;
    border: 2px solid $primary;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-sizing: border-box; // Ensure the border is included in the width and height

    &:checked::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      background-color: $primary;
      border-radius: 2px;
    }
  }

  label {
    font-size: 1rem;
    color: #333;

    a {
      color: $primary;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.flash {
    animation: flash 0.6s;
  }
}

@keyframes flash {
  0% {
    background-color: $danger2;
  }
  100% {
    background-color: transparent;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.addressContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $light1;
  padding: 16px;
  border-radius: 4px;

  code {
    flex: 1;
    word-break: break-all;
    font-size: 14px;
  }
}

.copyButton {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: $light;
  padding: 4px 8px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }
}
