//colors
$light: #fff;
$light1: #f1f1f1;
$light2: #CCCCCC;

$dark: #000;
$dark1: #111;
$dark2: #222;
$dark7: #777;
$darkish: #000d;
$primary: #4fd3d3;
$canvaright: #904BE9;
$modalbackdrop: #0C0C15;
$danger: #ff0000;
$danger2: rgba(255, 0, 0, 0.5);

//gradients
$grad: linear-gradient(270deg, rgba(144, 75, 233, 0.5) 0%, rgba(217, 217, 217, 0) 100%);
$canvagrad: linear-gradient(90deg, #01C3CC 8.25%, #4A80F1 51.39%, #904BE9 91.47%);
$solargrad: linear-gradient(90deg, #F83B00 0%, #EE5600 27.62%, #CF097B 56.41%);
$backgroundGrad: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(144, 75, 233, 0.5) 100%);
$backgroundGrad1: linear-gradient(180deg, rgba(1, 195, 204, 0) 8.25%, rgba(1, 195, 204, 0.15) 27.42%, rgba(74, 128, 241, 0.375) 51.39%, rgba(144, 75, 233, 0.75) 91.47%);
$nftCardBackground: linear-gradient(180deg, #904BE9 0%, #000000 66.28%);
//breakpoints
$tablet: 900px;
$phone: 640px;
