@import "../../../assets/scss/abstracts/variables";

$borderRadius: 8px;

.nftCard__container {
  display: flex;
  gap: 20px;
  background: $nftCardBackground;
  color: $light;
  // border: 1px solid #e5e5e5;
  border-radius: $borderRadius;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  width: 700px;

  @media (max-width: $tablet) {
    flex-direction: column;
    width: calc(100vw - 40px);
  }
}
.nftCard__video {
  width: 300px;
  overflow: hidden;
  border-radius: $borderRadius 0 0 $borderRadius;
  background-size: cover;
  background-position: center;

  @media (max-width: $tablet) {
    width: 100%;
    border-radius: $borderRadius $borderRadius 0 0;
  }
}
.nftCard__details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}
.nftCard__title {
  font-size: 36px;
  margin-bottom: 20px;
}
.nftCard__text {
  flex: 1;
  background-clip: text;
}
.nftCard__table {
  width: 100%;
  td:last-of-type {
    text-align: right;
    font-weight: 700;
  }

  td {
    padding: 4px 0;
  }
}
.nftCard__button_container {
  width: 100%;
  margin-top: 20px;
}
.nftCard__button {
  font-weight: 700;
  font-size: 18px;
  padding: 1rem;
}
